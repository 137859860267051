<template>
  <!-- eslint-disable vue/no-v-html vue/camelcase -->
  <div>
    <template v-if="!loadingLandingPage && business !== null && typeof business === 'object'">
      <div class="row justify-content-center">
        <div class="col-md-6 mt-5">
          <div class="card">
            <div class="card-header text-white">Add Review Request Contact for {{business.business_name}}</div>
            <div class="card-body">
              <form @submit.prevent="submitForm">
                <div class="form-group">
                  <label for="firstName">First Name:</label>
                  <input id="firstName" v-model="firstName" type="text" class="form-control" required />
                </div>
                <div class="form-group">
                  <label for="lastName">Last Name:</label>
                  <input id="lastName" v-model="lastName" type="text" class="form-control" />
                </div>
                <div class="form-group rr-form">
                  <label for="cellphone">Cell Phone:</label>
                  <VuePhoneNumberInput ref="vue_tel" v-model="cellphone" class="" :error="cellphone && !phoneObject.valid" valid-color="#e2e7f1" @update="phoneUpdated" />
                </div>
                <div class="form-group">
                  <label for="email">Email:</label>
                  <input id="email" v-model="email" type="email" class="form-control" />
                </div>
                <div class="form-group">
                  <label for="employee">Employee:</label>
                  <select v-model="employee_id" class="form-control">
                    <option v-for="(employee,i) in employees" :key="i" :value="employee.id">{{employee.name}}</option>
                  </select>
                </div>
                <div v-if="errorMessage !== ''" class="text-danger mb-2">{{errorMessage}}</div>
                <button type="submit" class="btn btn-primary"><b-spinner v-if="formWorking" small /> Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div v-else-if="status === 404" class="d-flex align-items-center justify-content-center vh-100 bg-primary m-0 p-0 error-div">
      <h1 class="display-4 fw-bold text-white text-center">
        <span>
          Business Not Found
        </span>
      </h1>
    </div>
    <div v-else class="text-center mt-4">
      <b-spinner></b-spinner>
    </div>
  </div>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import Vue from "vue";
export default {
  components:{
    VuePhoneNumberInput
  },
  props:{
  },
  data() {
    return {
      loadingLandingPage: false,
      formWorking:false,
      business:null,
      status:200,
      firstName: '',
      lastName: '',
      cellphone: null,
      email: '',
      employee_id:null,
      employees:[],
      country:'US',
      phoneObject: {
        e164:'',
        countryCode:'',
        isValid:false
      },
      errorMessage:''
    }
  },
  metaInfo () {
    return {
      title: 'Review ' + this.business?.business_name,
      meta: [
        { name: 'og:title', content: 'Review ' + this.business?.business_name },
      ],

    }
  },
  computed: {

  },

  mounted() {
    // If we are dealing with a card, set a custom business ID we will parse in the API
    this.getBusiness();
  },

  methods: {
    phoneUpdated(phone){
      this.phoneObject = phone;
    },
    submitForm(){
      // /businesses/{business}/create-single-contact
      if (this.$route.params.unique_id) {
        this.errorMessage = '';
        if(this.cellphone && !this.phoneObject.isValid){
          this.errorMessage = "Phone is not valid."
          return;
        }
        this.formWorking = true;
        this.$store
            .dispatch('landingPage/submitContact', {
              first_name: this.firstName,
              last_name: this.lastName,
              email: this.email,
              cell_phone: this.phoneObject.e164,
              country: this.phoneObject.countryCode,
              business_id:this.business.id,
              employee_id:this.employee_id
            })
            .then((res) => {
              this.formWorking = false;
              /* this.business = res?.data?.business; */
              if(res.success) {
                this.status = res?.data?.status;
                this.loadingLandingPage = false;
                Vue.$toast.open({
                  message: 'Contact Added',
                  type: 'success',
                  duration: 5000,
                })
                // Reset
                this.phoneObject.e164 = '';
                this.firstName = '';
                this.lastName = '';
                this.email = '';
                this.cellphone = '';
              }else{
                Vue.$toast.open({
                  message: res.message,
                  type: 'error',
                  duration: 5000,
                })
              }
            })
            .catch((error) => {
              console.log(error);
              this.loadingLandingPage = false
              this.formWorking = false;
            })
      }
    },
    getBusiness() {
      this.loadingLandingPage = true;
      if (this.$route.params.unique_id) {
        this.$store
            .dispatch('landingPage/getBusiness', this.$route.params.unique_id)
            .then((res) => {
              this.business = res?.data?.business;
              this.employees = [{employee_id:null, name:'Unknown'}, ...res?.data?.business.employees];
              this.status = res?.data?.status;
              this.loadingLandingPage = false;
            })
            .catch((error) => {
              console.log(error);
              this.loadingLandingPage = false
            })
      }
    }
  },
}
</script>
<style>
@import "https://fonts.googleapis.com/css2?family=Raleway&display=swap";
</style>
<style lang="scss">
  .rr-form {
    .country-selector__input,.input-tel__input{
      border-color: #e2e7f1;
    }
    .input-tel.is-valid{
      .input-tel__input{
        border-color: #e2e7f1 !important;
      }
      label{
        color: #e2e7f1 !important;
      }
    }
    .input-tel__input::placeholder{visibility: hidden}
  }
</style>
